import React, {FunctionComponent, useState} from 'react';
import {PageHero} from '../../components/page-hero';
import {Form, Input, TextArea} from '../../components/form';
import {ContactPageProps, ContactFormResultData} from '../../types';
import {Translate} from '../../components/translations';
import Button from '../../components/button';
import {Modal} from '../../components/modal';
import Layout from '../../components/layout/layout';
import {useMutation} from '@apollo/react-hooks';
import {CONTACT_FORM_SUBMIT} from '../../graphql/mutations';
import {toggleLastURL} from '../../helpers';

import './styles.scss';

const ContactPage: FunctionComponent<ContactPageProps> = () => {
  const [contactMutation, {loading}] = useMutation<ContactFormResultData>(CONTACT_FORM_SUBMIT);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async () => {
    setFormData({...formData, error: false, errorMessage: ''});
    const {data} = await contactMutation({
      variables: {
        name: formData.name,
        email: formData.email,
        subject: `Hexomatic: ${formData.subject}`,
        message: formData.message,
        emailTo: 'support@hexomatic.com',
        productType: 'Hexomatic'
      },
    });
    if (!data) return;
    const {
      contactFormSubmit: {error, errorMessage},
    } = data;
    if (error) {
      setFormData({...formData, error: true, errorMessage: errorMessage});
    } else {
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async (): Promise<void> => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    toggleLastURL();
  };

  return (
    <div>
      <Modal isOpen={formData.isModalOpen} modalToggle={modalToggle} title="CONTACT_SUCCESS_MESSAGE" />
      <Layout>
        <PageHero title="CONTACT_PAGE_TITLE" />
        <div className="contact_info_area sec_pad bg_color">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="contact_form">
                  <Form className="contact_form_box" onSubmit={submitHandler}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group text_box input-text-weight">
                          <Translate name="CONTACT_PAGE_NAME_PLACEHOLDER">
                            {text => (
                              <Input
                                required
                                type="text"
                                name="name"
                                placeholder={text}
                                value={formData.name}
                                onChange={changeHandler}
                              />
                            )}
                          </Translate>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group text_box input-text-weight">
                          <Translate name="CONTACT_PAGE_EMAIL_PLACEHOLDER">
                            {text => (
                              <Input
                                required
                                type="email"
                                name="email"
                                placeholder={text}
                                value={formData.email}
                                onChange={changeHandler}
                              />
                            )}
                          </Translate>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group text_box input-text-weight">
                          <Translate name="CONTACT_PAGE_SUBJECT_PLACEHOLDER">
                            {text => (
                              <Input
                                required
                                type="text"
                                name="subject"
                                placeholder={text}
                                value={formData.subject}
                                onChange={changeHandler}
                              />
                            )}
                          </Translate>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group text_box form-contact">
                          <Translate name="CONTACT_PAGE_MESSAGE_PLACEHOLDER">
                            {text => (
                              <TextArea
                                rows={5}
                                onChange={changeHandler}
                                name="message"
                                placeholder={text}
                                value={formData.message}
                                maxLength={2000}
                                disableResize={true}
                              />
                            )}
                          </Translate>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        {formData.error && (
                          <div className="alert alert-danger">
                            <Translate name={formData.errorMessage} />
                          </div>
                        )}
                        <div className="text-right">
                          <Button primary loading={loading}>
                            <Translate name="SEND_MESSAGE" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ContactPage;
